import React from "react";
import { Drawer, useTheme, useMediaQuery, List,ListItem ,ListItemText } from "@material-ui/core";
import { NavList } from "./NavList";
import { useNavDrawerStyles } from "./NavDrawerStyles";
import { useAuth } from "../data/auth/hooks";

interface NavDrawerProps {
	open: boolean;
	toggleOpen: () => void;
}

export const NavDrawer: React.FunctionComponent<NavDrawerProps> = props => {
	const { open, toggleOpen } = props;
	const styles = useNavDrawerStyles();
	const theme = useTheme();
	const permanentDrawer = useMediaQuery(theme.navDrawer.permanentBreakpoint);

	const [auth] = useAuth();

	if (auth.status !== "successful") {
		return null;
	}

	let bddlogo2 = auth.user.hasLogo === 2 || auth.user.hasLogo === 3 ? require("'../../../public/"+auth.user.companyId+"_logo2.jpg") : 0

	
	return (
		<nav className={styles.drawer}>
			<Drawer
				variant={permanentDrawer ? "permanent" : "temporary"}
				open={permanentDrawer || open}
				onClose={toggleOpen}
				classes={{
					paper: styles.drawerPaper,
				}}
				ModalProps={{
					keepMounted: true,
				}}
			>
				<NavList />
				<List style={{ marginTop: `auto` }} >
					<ListItem>
					{(auth.user.hasLogo === 2 || auth.user.hasLogo === 3) && bddlogo2 !==0 && (<ListItemText><img src={bddlogo2} className={styles.logo}/> </ListItemText>)}
					</ListItem>
				</List>
			</Drawer> 
		</nav>
	);
};
