import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const useLoginStyles = makeStyles((theme: Theme) => {
	
	return createStyles({
		loginDrawerPaper: {
			width: "100vw",
			[theme.breakpoints.only("sm")]: {
				width: "60vw",
			},
			[theme.breakpoints.up("sm")]: {
				width: "50vw",
			},
			[theme.breakpoints.up("lg")]: {
				width: "30vw",
			},
		},
		wraper: {
			position: "relative", // Dodajemo kako bi video mogao biti pozadina
			height: "100vh", // Podesite visinu na 100vh da pokrije celu visinu ekrana
		  },

		videoBackground: {
			position: "absolute",
			top: "50%",
			left: "50%",
			width: "100%",
			height: "100%",
			objectFit: "cover",  // Osigurava da video popuni celu pozadinu
			transform: "translate(-50%, -50%)", // Centriranje videa
			zIndex: -1, // Video ide ispod sadržaja
		  },
	  
		  videoBackgroundFallback: {
			position: "absolute",
			top: "50%",
			left: "50%",
			width: "100%",
			height: "100%",
			objectFit: "cover",
			transform: "translate(-50%, -50%)",
			zIndex: -1,
		  },
		  
		formItem: {
			"&:not(:first-child)": {
				marginTop: theme.spacing(2),
			},
			width: "60%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		textField: {
			width: "100%",
		},
		formContainer: {
			height: "100%",
		},
		localeSelection: {
			width: "60%",
			display: "flex",
			justifyContent: "flex-end",
			alignItems: "center",
		},
		alert: {
			position: "absolute",
			top: theme.spacing(2),
			width: "100%",
		},
	});
});


