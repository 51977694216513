import React, { useState, useEffect } from "react";
import { Drawer, CssBaseline } from "@material-ui/core";
import { LoginForm } from "./LoginForm";
import { useLoginStyles } from "./useLoginStyles";

export const LoginPage: React.FunctionComponent = props => {
	const styles = useLoginStyles();
	const [isVideoAvailable, setIsVideoAvailable] = useState(false);

	const basePath = process.env.PUBLIC_URL || '';

	useEffect(() => {
		// Provera dostupnosti video fajla
		const checkVideoAvailability = async () => {
			try {
				const response = await fetch(`${basePath}/log-in-video.mp4`, { method: "HEAD" });
				setIsVideoAvailable(response.ok); // Ako je video dostupan, postavlja na true
			} catch {
				setIsVideoAvailable(false); // Ako fetch ne uspe, postavlja na false
			}
		};

		checkVideoAvailability();
	}, []);

	return (
		<>
			<CssBaseline />
			<div className={styles.wraper}>
				{isVideoAvailable ? (
					<video
						autoPlay
						muted
						loop
						className={styles.videoBackground}
						poster={`${basePath}/login-register.jpg`}
					>
						<source src={`${basePath}/log-in-video.mp4`} type="video/mp4" />
						Vaš preglednik ne podržava video tag.
					</video>
				) : (
					<img
						src={`${basePath}/login-register.jpg`}
						alt="login background"
						className={styles.videoBackgroundFallback}
					/>
				)}

				<Drawer
					anchor="right"
					variant="permanent"
					classes={{
						paper: styles.loginDrawerPaper,
					}}
					PaperProps={{
						elevation: 6,
					}}
				>
					<LoginForm />
				</Drawer>
			</div>
		</>
	);
};


